import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  Typography,
  Input,
  Divider,
  Form,
  Button,
  Select,
  Card,
  Spin,
  Modal,
  Checkbox,
} from 'antd';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { baseurl } from '../../components/lib/settings';
import "./company.css"
import { ErrorAlert, SuccessAlert } from '../../components/Alert/alert_message';

const { Title } = Typography;
const { Option } = Select;
const { Meta } = Card;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function Company() {

  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fieldsValues, setFieldsValue] = useState();
  const [isModal, setIsModal] = useState(false);
  const [feeDetails, setFeeDetails] = useState({});
  const [payDebit, setPayDebit] = useState(false);
  const [message, setMessage] = useState("");
  const company = useLocation()
  const navigate = useNavigate()


  useEffect(() => {
    setLoading(true);
    let companyId = { company: company.state['id'] }
    console.log("EMPRESA: ", company.state['id'])
    baseurl.get('v1/pay/fee/', { params: companyId }).then((resp) => {
      setFeeDetails(resp.data)
      setLoading(false);
    })
    setUpdate(false)
  }, [update]);

  const onFinish = (values) => {
    const data = {
      company: company.state['id'],
      monthlyfeeID: feeDetails['monthFeeID'],
      amount: values.amount,

    }
    navigate('/payment/methods', { state: data })
  };
  const confirm = () => {
    setIsModal(true)
  };
  const handleChange = (values) => {
    console.log(values)
    setFieldsValue({
      sights: [],
    });
  };

  const payWithDebit = (e) => {
    setPayDebit(e.target.checked);
  };

  const generateInvoice = () => {
    navigate("/invoice/search", { state: company.state['id'] })
  }

  const editCompanyData = () => {
    navigate("/edit/company/account/", { state: company.state })
  }

  return (
    <div>
      <Row
        type="flex"
        style={{
          alignItems: "center"
        }}
        justify="center"
        block>
        <Col xs={20} sm={16} md={12} lg={12} xl={12}>
          {error && <ErrorAlert message={message} />}
          {success && <SuccessAlert message={message} />}

          <Card
            style={{ width: '100%', marginTop: 16 }}
            // extra={<><h1>Taxa Mensal: {feeDetails['monthFee']}</h1></>}
            actions={[
              <Button
                onClick={confirm}
              >Pagar agora</Button>,
              <Button type='primary' ghost onClick={generateInvoice}> Gerar factura</Button>,
              <Button type="dashed" danger onClick={editCompanyData}>Editar</Button>,
            ]}
          >
            {/* <Meta
              title={`${company.state['description']}`}
              description={`Description`}
            /> */}
            <div className='main'>
              {loading ? <Spin indicator={antIcon} /> : <>
                <div className='right'><b>Taxa mensal: </b> {feeDetails['monthFee']}</div>
                <div className='right'><b>Dívida: </b> {feeDetails['totalDebit']}</div>
              </>}

            </div>
            <div className='left'><b>Nome: </b>{company.state['description']}</div>
            <div className='left'><b>NUIT: </b>{company.state['nuit']}</div>
            <div className='left'><b>Licença: </b>{company.state['licenca']}</div>
            <div className='left'><b>Tel: </b>{company.state['telemovel']}</div>
          </Card>
          <br />
          {isModal && <>
            <Form
              disabled={loading}
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                name="amount"
                label="Insira o Valor"
                rules={[{
                  required: true,
                  message: 'Por favor introduza o valor a pagar.'
                }]}
              >
                <Input
                  placeholder="Valor em MT" disabled={loading}
                />
              </Form.Item>
              <Form.Item >
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  block>
                  Processar pagamento
                </Button>
              </Form.Item>
            </Form>
          </>}
        </Col>

      </Row>

    </div>
  )
}

export default Company;