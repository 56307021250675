import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Typography,
    Input,
    Form,
    Button,
    Row,
    Col,
    Card
} from "antd"
import { baseurl } from '../../components/lib/settings';
import { ErrorAlert, SuccessAlert } from '../../components/Alert/alert_message';

function OTP() {
    const [loading, setLoading] = useState(false);
    const [reSendLoading, setReSendLoading] = useState(false);
    const [otp, setOtp] = useState(true);
    const [sendOtp, setSendOtp] = useState(false);
    const [company, setCompany] = useState({});
    const [message, setMessage] = useState("Código inválido!")
    const navigate = useNavigate()
    const getNuit = useLocation()


    const onFinish = (values) => {
        setLoading(true)
        const otp = values.otp
        baseurl.post('v1/validator_otp/', { otp }).then((resp) => {
            setOtp(true);
            setLoading(false);
            setCompany(resp.data['company'])
            navigate("/payment/tax", { state: resp.data['company'] })
        }).catch(function (error) {
            if (error.response.status === 400) {
                if (error.response.data === "Used") {
                    setMessage("O código já foi usado.")
                }
            }
            setOtp(false);
            setLoading(false)
        })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const rSendOTP = () => {
        setReSendLoading(true)
        let nuit = getNuit.state['nuit']
        baseurl.post('v1/check_nuit/', { nuit }).then((resp) => {
            setSendOtp(true)
            localStorage.setItem(true, "authToken")
            setReSendLoading(false);
        }).catch(function (error) {
            setMessage("Desculpe, não foi possível enviar o códio.")
            setReSendLoading(false)
            setOtp(false);
        })
    }

    const { Title } = Typography;

    return (
        <div>
            <br />

            <Row
                type="flex"
                style={{
                    alignItems: "center"
                }}
                justify="center"
                block>
                <Col xs={20} sm={16} md={12} lg={12} xl={12}>
                    {!otp && <ErrorAlert message={message} />}
                    {sendOtp && <SuccessAlert message={'Código enviado.'} />}
                    <br />
                    <Typography>
                        <Title
                            align="center"
                            level={5}>
                            Introduza o códico de verificação
                        </Title>
                    </Typography>
                    <br />

                    <Card
                        hoverable>
                        <Form
                            // disabled={true}
                            name="basic"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                name="otp"
                                rules={[{
                                    required: true,
                                    message: 'Por favor introduza o códico.'
                                }]}
                            >
                                <Input
                                    placeholder="Códico" disabled={loading}
                                />
                            </Form.Item>
                            <Form.Item >
                                <Button
                                    loading={loading}
                                    type="primary"
                                    htmlType="submit"
                                    block>
                                    Validar
                                </Button>
                            </Form.Item>
                        </Form>
                        {!otp && <Button
                            loading={reSendLoading}
                            type="primary"
                            htmlType="submit"
                            onClick={rSendOTP}
                            block>
                            Reenviar Codico
                        </Button>}
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default OTP;
