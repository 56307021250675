import { Alert, message } from "antd";

export function ErrorAlert(props){
   return <Alert message={props.message} type="error" showIcon closable />
}

export function SuccessAlert(props){
    return <Alert message={props.message} type="success" showIcon closable />
}

export function WarningAlert(props){
    return <Alert message={props.message} type="warning" showIcon closable />
}


export function GenericSuccessMessage(msg) {
    message.success(msg);
  };


  export function GenericErrorMessage(msg) {
    message.error(msg);
  };

  export function SomeThingErrorMessage() {
    message.error('Algo correu mal.');
  };