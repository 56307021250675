import { Modal } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';

const  modalConfirm = (message) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: `${message}`,
      okText: 'OK',
    });
  };

export default modalConfirm