import React, { useState, useEffect } from 'react'
import {
    Row,
    Col,
    Typography,
    Space,
    Table,
    Tag,
    DatePicker,
    Card,
    Button,
    Form,
    Divider,
    Select

} from "antd"
import {
    SearchOutlined
} from "@ant-design/icons"
import { baseurl } from '../../components/lib/settings';
import { useLocation } from 'react-router-dom';

const { Title } = Typography;
const { Option } = Select;
const columns = [
    {
        title: 'Referencia',
        dataIndex: 'referencia',
        key: 'referencia',
        render: (text) => <a>{text}</a>,
    },
    {
        title: 'Valor',
        dataIndex: 'valor',
        key: 'valor',
    },
    {
        title: 'Valor Pago',
        dataIndex: 'valorpago',
        key: 'valorpago',
    },
    {
        title: 'Data de pagamento',
        key: 'datapagamento',
        dataIndex: 'datapagamento',
    },
    {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <a>Imprimir {record.referencia}</a>
                <a>Visualizar</a>
            </Space>
        ),
    },
];




const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};

const { RangePicker } = DatePicker;
function SearchInvoice() {
    const [loading, setLoading] = useState(false)
    const [fee, setFee] = useState([])
    const [firstDate, setFirstDate] = useState()
    const [lastDate, setLastDate] = useState()
    const [status, setStatus] = useState()
    const comapny = useLocation()

    const onDateSet = (date, dateString) => {
        console.log(date, dateString);
        setFirstDate(dateString[0])
        setLastDate(dateString[1])
    };


    const antddata = fee.map((item) => (
        {
            key: item.id,
            referencia: item.id,
            valor: item.amount,
            valorpago: item.pay_amount,
            datapagamento: item.updated_at,
        }
    ));
    

    const onStatusSet = (value) => {
        console.log(value);
        setStatus(value)
    };
    const onFinish = (values) => {
        setLoading(true)
        console.log('Success:', values);
        const search =  {
            company:comapny.state,
            status:status,
            first_date:firstDate,
            final_date:lastDate,
        
        }
        baseurl.post('v1/fee/report/', search).then((resp) => {
            setFee(resp.data)
            setLoading(false)
        })
    };
    return (
        <div>
            <Row
                type="flex"
                style={{
                    alignItems: "center"
                }}
                justify="center"
                block>
                <Col xs={20} sm={16} md={12} lg={12} xl={12}>

                    <br />
                    <Typography>
                        <Title
                            align="center"
                            level={5}>
                            Facturas
                        </Title>
                    </Typography>
                    <br />
                    {/* <Space direction="vertical">
                        <Space direction="vertical" size={12}>
                        </Space>
                    </Space> */}
                    <Card
                        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 1px 5px" }}
                    >

                        {/* <div className='left'></div> */}
                        {/* <div className='right'>Pesquisar factura:   <RangePicker onChange={onChange} picker="month" /> <Button><SearchOutlined /></Button></div> */}

                        <Form
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >


                            <Row gutter={[24, 0]}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
                                    <Form.Item
                                        label="Pesquisar factura:"
                                        name="date"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor selecione ${name}',
                                            },
                                        ]}
                                    >
                                        <RangePicker onChange={onDateSet} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
                                    <Form.Item
                                    >
                                        <Select
                                            // defaultValue="lucy"
                                            // style={{
                                            //     width: 120,
                                            // }}
                                            // loading
                                            onChange={(e) => onStatusSet(e)}
                                            placeholder="Estado das facturas"
                                        >
                                            <Option value="Pago">Pagas</Option>
                                            <Option value="Pendente">Pendentes</Option>

                                        </Select>
                                    </Form.Item>

                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
                                    <Form.Item
                                    >
                                        <Button loading={loading} htmlType="submit" >
                                            <SearchOutlined />
                                        </Button>
                                    </Form.Item>

                                </Col>
                            </Row>
                        </Form>
                    </Card>
                    <br />
                    {fee.length > 0 && <Card
                        hoverable
                        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 5px" }}
                    >
                        <Table loading={loading} columns={columns} dataSource={antddata} />
                        <br />
                    </Card>}
                    <br />
                </Col>
            </Row>
        </div >
    )
}

export default SearchInvoice
