import React, { useState, useEffect } from 'react'
import {
  useNavigate,
  useLocation
} from "react-router-dom"
import {
  Row,
  Col,
  Typography,
  Card,
  Button,
  Form,
  Input,
  Divider
} from "antd"
import { baseurl } from '../../components/lib/settings';
import modalConfirm from '../../components/Modal/success';
import { ErrorAlert } from '../../components/Alert/alert_message';

const { Title } = Typography;

function PaymentMethod() {

  const [loading, setLoading] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [update, setUpdate] = useState(false);
  const [error, setError] = useState(false);
  const [feeDetails, setFeeDetails] = useState({});


  const company = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true);
    let companyId = { company: company.state['company'] }
    console.log("EMPRESA no payment: ", company.state['company']['monthlyfeeID'])
    baseurl.get('v1/pay/fee/', { params: companyId }).then((resp) => {
      setFeeDetails(resp.data)
      setLoading(false);
    })
    setUpdate(false)
  }, [update]);

  const onFinish = (values) => {
    setLoading(true)
    const data = {
      company: company.state['company'],
      monthlyfeeID: company.state['monthFeeID'],
      amount: company.state['amount'],
      payDebit: true,
      phone: '258' + values.phone
    }
    console.log("Meu data para pagamento", data)
    baseurl.post('v1/pay/fee/', data).then((resp) => {
      setMessage("Pagamento efetuado com sucesso")
      setSuccess(true)
      setIsModal(false);
      setUpdate(true);
      setLoading(false);
      modalConfirm("Pagamento efetuado com sucesso")
    }).catch(function (error) {
      if (error.response.status == 400) {
        setMessage(error.response.data)
        setLoading(false)
        setError(true)
      }
      setMessage("Desculpe ocorreu um erro inesperado")
      setLoading(false)
      setError(true)
    })
  };

  const paymentMethod = () => {
    setIsModal(true)
  };

  const paymentMethodTransferencia = () => {
    setIsModal(false)
  }

  return (
    <div>
      <Row
        type="flex"
        style={{
          alignItems: "center"
        }}
        justify="center"
        block>
        <Col xs={20} sm={16} md={12} lg={12} xl={12}>
          {error && <ErrorAlert message={"Não foi possivel processar o seu pagamento"} />}
          <Typography>
            <Title
              // align="right"
              level={5}>
              <Divider orientation='left'>Métodos de Pagamento</Divider>
            </Title>
          </Typography>
          <br />
          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
              <Card hoverable style={{ textAlign: "center", backgroundColor: "lightgreen" }} onClick={paymentMethod} >M-Pesa / E-Mola</Card>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
              <Card hoverable style={{ textAlign: "center" }} onClick={paymentMethodTransferencia} >Transferência bancária</Card>
            </Col>
          </Row>
          {isModal && <>
            <br />
            <Form
              disabled={loading}
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                name="phone"
                label="Insira o número a pagar"
                rules={[{
                  required: true,
                  message: 'Por favor introduza o número de telemóvel.'
                }]}
              >
                <Input
                  placeholder="Número" disabled={loading}
                />
              </Form.Item>
              <Form.Item >
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  block>
                  Processar pagamento
                </Button>
              </Form.Item>
            </Form>
          </>}
        </Col>
      </Row>
    </div>
  )
}

export default PaymentMethod;