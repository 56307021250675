import React, { useState, useEffect } from 'react'
import {
    Form,
    Row,
    Col, Divider,
    Typography,
    Input,
    Select,
    Button,
} from "antd"
import { baseurl } from '../../components/lib/settings';
import { useLocation, useNavigate } from "react-router-dom"

const { Title } = Typography;
const { Option } = Select;

const industriaHoteleira = [
    {
        label: 'Hotéis',
        value: 'Hotéis',
    },
    {
        label: 'Restaurante',
        value: 'Restaurante',
    },
    {
        label: 'Bares',
        value: 'Bares',
    },
    {
        label: 'Quiosqueires e baracas',
        value: 'Quiosqueires e baracas',
    },
    {
        label: 'Outras (especifique)',
        value: 'Outras (especifique)',
    },
];

const tipoComercio = [
    {
        label: 'Supermercados',
        value: 'Supermercados',
    },
    {
        label: 'Mercearias',
        value: 'Mercearias',
    },
    {
        label: 'Ferragens',
        value: 'Ferragens',
    },
    {
        label: 'Electrodomésticos',
        value: 'Electrodomésticos',
    },
    {
        label: 'Modas e confecções',
        value: 'Modas e confecções',
    },
];


const serviçosPublicos = [
    {
        label: 'PrestaçãoPrestação de serviços(bancos, escritórios, etc)',
        value: 'PrestaçãoPrestação de serviços(bancos, escritórios, etc)',
    },
    {
        label: 'Instituições públicas',
        value: 'Instituições públicas',
    },
    {
        label: 'Estabelecimento de ensino',
        value: 'Estabelecimento de ensino',
    },
    {
        label: 'Unidade sanitária',
        value: 'Unidade sanitária',
    },
    {
        label: 'Instituições militares e policiais',
        value: 'Instituições militares e policiais',
    },
];



const tipoResiduos = [
    {
        label: 'Domésticos',
        value: 'Domésticos',
    },
    {
        label: 'Orgânicos',
        value: 'Orgânicos',
    },
    {
        label: 'Verdes',
        value: 'Verdes',
    },
    {
        label: 'Entulhos',
        value: 'Entulhos',
    },
    {
        label: 'Metálicos (Sucata)',
        value: 'Metálicos (Sucata)',
    },
    {
        label: 'Específicos da produção',
        value: 'Específicos da produção',
    },
    {
        label: 'Cartonagem e embalagem',
        value: 'Cartonagem e embalagem',
    },
    {
        label: 'Escritórios',
        value: 'Escritórios',
    },
    {
        label: 'Outros (especifique)',
        value: 'Outros (especifique)',
    },
    {
        label: 'Volume/Peso Total',
        value: 'Volume/Peso Total',
    },
];


function EditAccount() {

    const [fieldsValues, setFieldsValue] = useState();
    const [checked, setChecked] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const company = useLocation();
    const navigate = useNavigate();

    const handleChange = (values) => {
        console.log(values)
        setFieldsValue({
            sights: [],
        });
    };

    const onChange = (e) => {
        setChecked(e.target.checked);
    };

    const onFinish = (values) => {
        setLoading(true)
        const data = {
            description: values.description !== undefined ? values.description : company.state['description'],
            nuit: values.nuit !== undefined ? values.nuit : company.state['nuit'],
            endereco: values.endereco !== undefined ? values.endereco : company.state['endereco'],
            licenca: values.licenca !== undefined ? values.licenca : company.state['licenca'],
            telefone: values.telefone !== undefined ? "258" + values.telefone : company.state['telefone'],
            telemovel: values.telemovel !== undefined ? "258" + values.telemovel : company.state['telemovel'],
            industriaHoteleira: values.industriaHoteleira !== undefined ? values.industriaHoteleira : company.state['industriaHoteleira'],
            comercio: values.comercio !== undefined ? values.comercio : company.state['comercio'],
            servicosPublicos: values.servicosPublicos !== undefined ? values.servicosPublicos : company.state['servicosPublicos'],
            tipoResiduos: values.tipoResiduos !== undefined ? values.tipoResiduos : company.state['tipoResiduos'],
            volume: values.volume !== undefined ? values.volume : company.state['volume'],
            pesos: values.pesos !== undefined ? values.pesos : company.state['pesos'],
        }
        console.log("VARIAVEL CORDA", data)

        baseurl.post('v1/update_company_data/', data).then((resp) => {
            setLoading(false);
        navigate("/payment/tax", { state: resp.data })

        }).catch(function (error) {
            setLoading(false)
        })
    };

    return (
        <div>
            <Row
                type="flex"
                style={{
                    alignItems: "center"
                }}
                justify="center"
                block>
                <Col xs={20} sm={16} md={12} lg={12} xl={12}>
                    <Typography>
                        <Title
                            level={5}>
                            Ficha de Prova de Serviço
                        </Title>
                        Produção <b>Diária</b> de Resíduos Sólidos Urbanos (RSU)
                    </Typography>
                    <br />
                    <Form
                        name="basic"
                        layout="vertical"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        disabled={loading}
                        autoComplete="off"
                    >
                        <Divider orientation="left" plain>
                            <b>Declaração da entidade productora de resíduos</b>
                        </Divider>
                        <Form.Item
                            name="description"
                            label="Nome"
                            value={company.state['description']}
                        >
                            <Input defaultValue={company.state['description']} />
                        </Form.Item>
                        <Form.Item
                            name="nuit"
                            label="NUIT:"

                            value={company.state['nuit']}
                        >
                            <Input disabled={true} defaultValue={company.state['nuit']} />
                        </Form.Item>
                        <Form.Item
                            name="endereco"
                            label="Endereço"
                        >
                            <Input defaultValue={company.state['endereco']} initialValues={company.state['endereco']} />
                        </Form.Item>
                        <Form.Item
                            name="licenca"
                            label="N° da licença/alvará"
                            value={company.state['licenca']}
                        >
                            <Input disabled={true} defaultValue={company.state['licenca']} />
                        </Form.Item>
                        <Form.Item
                            name="telefone"
                            label="Telefone"
                            value={company.state['telefone']}
                        >
                            <Input defaultValue={company.state['telefone']} />
                        </Form.Item>
                        <Form.Item
                            name="telemovel"
                            label="Telemóvel"
                            value={company.state['telemovel']}
                        >
                            <Input defaultValue={company.state['telemovel']} />
                        </Form.Item>
                        <Divider orientation="left" plain>
                            <b>Classificação da entidade</b>
                        </Divider>
                        <Form.Item
                            name="industriaHoteleira"
                            label="Indústria Hoteleira"
                            value={company.state['industriaHoteleira']}
                        >
                            <Select placeholder="Escolher" options={industriaHoteleira} onChange={handleChange} defaultValue={company.state['industriaHoteleira']} />
                        </Form.Item>
                        <Form.Item
                            name="comercio"
                            label="Comércio"
                            value={company.state['comercio']}
                        >
                            <Select placeholder="Escolher" options={tipoComercio} onChange={handleChange} defaultValue={company.state['comercio']} />
                        </Form.Item>
                        <Form.Item
                            name="servicosPublicos"
                            label="Serviços e instituições públicas"
                            value={company.state['servicosPublicos']}
                        >
                            <Select placeholder="Escolher" options={serviçosPublicos} onChange={handleChange} defaultValue={company.state['servicosPublicos']} />
                        </Form.Item>
                        <Divider orientation="left" plain>
                            <b>Tipo e volume de resíduos</b>
                        </Divider>
                        <Form.Item
                            name="tipoResiduos"
                            label="Tipo de resíduos"
                            value={company.state['tipoResiduos']}
                        >
                            <Select placeholder="Escolher" options={tipoResiduos} onChange={handleChange} defaultValue={company.state['tipoResiduos']} />
                        </Form.Item>
                        <Form.Item
                            name="volume"
                            label="Volume"
                            value={company.state['volume']}
                        >
                            <Input defaultValue={company.state['volume']} />
                        </Form.Item>
                        <Form.Item
                            name="pesos"
                            label="Pesos (Kg)"
                            value={company.state['pesos']}
                        >
                            <Input defaultValue={company.state['pesos']} />
                        </Form.Item>
                        <Form.Item >
                            <Button
                                type="primary"
                                htmlType="submit"
                                block
                            >
                                Actualizar dados
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

export default EditAccount;
