import 'antd/dist/antd.css';
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router";
import Header from './components/Header/Header';
import ReferencCheck from './pages/Reference/Reference';
import Company from './pages/Company/Company';
import CreateAccount from './pages/Create/CreateAccount';
import { baseurl } from './components/lib/settings';
import { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import OTP from './pages/OTP/verify_otp';
import SearchInvoice from './pages/Invoice/SearchInvoice';
import EditAccount from './pages/Create/EditAccount';
import PaymentMethod from './pages/Payments/PaymentMethod';


const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
function App() {
  const [authenticated, setAuthenticated] = useState(false)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    auth()
  })
  const auth = () => {
    const authToken = localStorage.getItem("authToken")
    if (authToken) {
      baseurl.get('').then((resp) => {
        setAuthenticated(true)
        setLoading(false)
      }).cacth(function (error) {
        setAuthenticated(false)
        setLoading(false)
      })

    } else {
      setAuthenticated(false)
      setLoading(false)
    }
  }
  return (
    <div className="routes">
      <Header />
      {loading ? <Spin indicator={antIcon} /> : <>{authenticated ? <BrowserRouter>
        <Routes>
          <Route path="/" element={< ReferencCheck />} />
          <Route path="/payment/tax" element={< Company />} />
          <Route path="/register/company" element={< CreateAccount />} />
          <Route path="/payment/methods" element={< PaymentMethod />} />
          <Route path="/confirmation" element={< OTP />} />
          <Route path="/invoice/search" element={< SearchInvoice />} />
          <Route path="/edit/company/account" element={<  EditAccount />} />

        </Routes>
      </BrowserRouter> : <BrowserRouter>
        <Routes>
          <Route path="/" element={< ReferencCheck />} />
          <Route path="/payment/tax" element={< Company />} />
          <Route path="/register/company" element={< CreateAccount />} />
          <Route path="/confirmation" element={< OTP />} />
          <Route path="/payment/methods" element={< PaymentMethod />} />
          <Route path="/invoice/search" element={< SearchInvoice />} />
          <Route path="/edit/company/account" element={<  EditAccount />} />

        </Routes>
      </BrowserRouter>}</>}

    </div>
  );
}

export default App;
