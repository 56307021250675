import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import {
    Typography,
    Input,
    Form,
    Button,
    Row,
    Col,
    Card
} from "antd"
import { baseurl } from '../../components/lib/settings';
import { ErrorAlert } from '../../components/Alert/alert_message';
import App from '../../App';

function Reference() {
    const [loading, setLoading] = useState(false);
    const [isCompany, setIsCompany] = useState(true);
    const navigate = useNavigate()


    const onFinish = (values) => {
        setLoading(true)
        const nuit = values.nuit
        baseurl.post('v1/check_nuit/', { nuit }).then((resp) => {
            localStorage.setItem(true, "authToken")
            setIsCompany(true);
            setLoading(false);
            navigate("/confirmation", {state: {'nuit': nuit}})
        }).catch(function (error) {
            setIsCompany(false);
            setLoading(false)
        })

    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const singin = () => {
        navigate("/register/company")
    }

    const { Title } = Typography;

    return (
        <div>
            <br />

            <Row
                type="flex"
                style={{
                    alignItems: "center"
                }}
                justify="center"
                block>
                <Col xs={20} sm={16} md={12} lg={12} xl={12}>
                    {!isCompany && <ErrorAlert message={"Nuit não encontrado"} />}
                    <br />
                    <Typography>
                        <Title
                            align="center"
                            level={5}>
                            Introduza o NUIT
                        </Title>
                    </Typography>
                    <br />

                    <Card
                        hoverable>
                        <Form
                            // disabled={true}
                            name="basic"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                name="nuit"
                                rules={[{
                                    required: true,
                                    message: 'Por favor introduza o NUIT.'
                                }]}
                            >
                                <Input
                                    placeholder="NUIT" disabled={loading}
                                />
                            </Form.Item>
                            <Form.Item >
                                <Button
                                    loading={loading}
                                    type="primary"
                                    htmlType="submit"
                                    block>
                                    Verificar
                                </Button>
                            </Form.Item>
                        </Form>
                        {!isCompany && <Button
                            type="primary"
                            htmlType="submit"
                            onClick={singin}
                            block>
                            Cadastrar Empresa
                        </Button>}
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Reference;