import React, { useState, useEffect } from 'react'

import {
    Form,
    Row,
    Col, Divider,
    Typography,
    Input,
    Select,
    Button,
    Checkbox
} from "antd"
import { baseurl } from '../../components/lib/settings';

const { Title } = Typography;
const { Option } = Select;


const industriaHoteleira = [
    {
        label: 'Hotéis',
        value: 'Hotéis',
    },
    {
        label: 'Restaurante',
        value: 'Restaurante',
    },
    {
        label: 'Bares',
        value: 'Bares',
    },
    {
        label: 'Quiosqueires e baracas',
        value: 'Quiosqueires e baracas',
    },
    {
        label: 'Outras (especifique)',
        value: 'Outras (especifique)',
    },


];




const tipoComercio = [
    {
        label: 'Supermercados',
        value: 'Supermercados',
    },
    {
        label: 'Mercearias',
        value: 'Mercearias',
    },
    {
        label: 'Ferragens',
        value: 'Ferragens',
    },
    {
        label: 'Electrodomésticos',
        value: 'Electrodomésticos',
    },
    {
        label: 'Modas e confecções',
        value: 'Modas e confecções',
    },


];


const serviçosPublicos = [
    {
        label: 'PrestaçãoPrestação de serviços(bancos, escritórios, etc)',
        value: 'PrestaçãoPrestação de serviços(bancos, escritórios, etc)',

    },
    {
        label: 'Instituições públicas',
        value: 'Instituições públicas',
    },
    {
        label: 'Estabelecimento de ensino',
        value: 'Estabelecimento de ensino',
    },
    {
        label: 'Unidade sanitária',
        value: 'Unidade sanitária',
    },
    {
        label: 'Instituições militares e policiais',
        value: 'Instituições militares e policiais',
    },
];



const tipoResiduos = [
    {
        label: 'Domésticos',
        value: 'Domésticos',

    },
    {
        label: 'Orgânicos',
        value: 'Orgânicos',

    },
    {
        label: 'Verdes',
        value: 'Verdes',

    },
    {
        label: 'Entulhos',
        value: 'Entulhos',

    },
    {
        label: 'Metálicos (Sucata)',
        value: 'Metálicos (Sucata)',

    },
    {
        label: 'Específicos da produção',
        value: 'Específicos da produção',

    },
    {
        label: 'Cartonagem e embalagem',
        value: 'Cartonagem e embalagem',

    },
    {
        label: 'Escritórios',
        value: 'Escritórios',

    },
    {
        label: 'Outros (especifique)',
        value: 'Outros (especifique)',

    },
    {
        label: 'Volume/Peso Total',
        value: 'Volume/Peso Total',

    },

];




function CreateAccount() {

    const [fieldsValues, setFieldsValue] = useState();
    const [checked, setChecked] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleChange = (values) => {
        console.log(values)
        setFieldsValue({
            sights: [],
        });
    };

    const onChange = (e) => {
        setChecked(e.target.checked);
    };


    const onFinish = (values) => {

        const data = {
            description: values.description,
            nuit: values.nuit,
            endereco: values.endereco,
            licenca: values.licenca,
            telefone: "258" + values.telefone,
            telemovel: "258" + values.telemovel,
            industriaHoteleira: values.industriaHoteleira,
            comercio: values.comercio,
            servicosPublicos: values.servicosPublicos,
            tipoResiduos: values.tipoResiduos,
            volume: values.volume,
            pesos: values.pesos,
            declaracaoHonra: checked,
        }

        baseurl.post('v1/create_company/', data).then((resp) => {
            setLoading(true);
        }).catch(function (error) {
            setLoading(false)
        })

    };



    return (
        <div>
            <Row
                type="flex"
                style={{
                    alignItems: "center"
                }}
                justify="center"
                block>
                <Col xs={20} sm={16} md={12} lg={12} xl={12}>
                    <Typography>
                        <Title
                            // align="right"
                            level={5}>
                            Ficha de Prova de Serviço
                        </Title>
                        Produção <b>Diária</b> de Resíduos Sólidos Urbanos (RSU)
                    </Typography>
                    <br />
                    <Form
                        // disabled={true}
                        name="basic"
                        layout="vertical"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        disabled={loading}
                        autoComplete="off"
                    >
                        <Divider orientation="left" plain>
                            <b>Declaração da entidade productora de resíduos</b>
                        </Divider>
                        <Form.Item
                            name="description"
                            label="Nome"
                            rules={[
                                {
                                    required: true,
                                    message: 'Introduza o ${label}.',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="nuit"
                            label="NUIT:"
                            rules={[
                                {
                                    required: true,
                                    message: 'Introduza o ${label}.',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="endereco"
                            label="Endereço"
                            rules={[
                                {
                                    required: true,
                                    message: 'Introduza o ${label}.',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="licenca"
                            label="N° da licença/alvará"
                            rules={[
                                {
                                    required: true,
                                    message: 'Introduza o ${label}.',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="telefone"
                            label="Telefone"
                            rules={[
                                {
                                    required: true,
                                    message: 'Introduza o ${label}.',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="telemovel"
                            label="Telemóvel"
                            rules={[
                                {
                                    required: true,
                                    message: 'Introduza o ${label}.',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Divider orientation="left" plain>
                            <b>Classificação da entidade</b>
                        </Divider>
                        <Form.Item
                            name="industriaHoteleira"
                            label="Indústria Hoteleira"
                            rules={[
                                {
                                    required: true,
                                    message: 'Escolha a ${label}.',
                                },
                            ]}
                        >
                            <Select placeholder="Escolher" options={industriaHoteleira} onChange={handleChange} />
                        </Form.Item>
                        <Form.Item
                            name="comercio"
                            label="Comércio"
                            rules={[
                                {
                                    required: true,
                                    message: 'Escolha a ${label}.',
                                },
                            ]}
                        >
                            <Select placeholder="Escolher" options={tipoComercio} onChange={handleChange} />
                        </Form.Item>
                        <Form.Item
                            name="servicosPublicos"
                            label="Serviços e instituições públicas"
                            rules={[
                                {
                                    required: true,
                                    message: 'Escolha a ${label}.',
                                },
                            ]}
                        >
                            <Select placeholder="Escolher" options={serviçosPublicos} onChange={handleChange} />
                        </Form.Item>
                        <Divider orientation="left" plain>
                            <b>Tipo e volume de resíduos</b>
                        </Divider>
                        <Form.Item
                            name="tipoResiduos"
                            label="Tipo de resíduos"
                            rules={[
                                {
                                    required: true,
                                    message: 'Escolha o tipo de resíduos.',
                                },
                            ]}
                        >
                            <Select placeholder="Escolher" options={tipoResiduos} onChange={handleChange} />
                        </Form.Item>
                        <Form.Item
                            name="volume"
                            label="Volume"
                            rules={[
                                {
                                    required: true,
                                    message: 'Introduza o ${label}.',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="pesos"
                            label="Pesos (Kg)"
                            rules={[
                                {
                                    required: true,
                                    message: 'Introduza o ${label}.',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Divider orientation="left" plain>
                            <b>Declaração de Honra</b>
                        </Divider>
                        <Form.Item
                            name="declaracaoHonra"
                        >
                            <Checkbox onChange={onChange}>A presente declaração vai por mim assinada e com uso do carimbo da instituição acima e corresponde a verdade e não omite quaisquer dados que nela vai constar.</Checkbox>
                        </Form.Item>
                        <Form.Item >
                            <Button
                                type="primary"
                                htmlType="submit"
                                block
                                disabled={!checked}
                            >
                                Cadastrar
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

export default CreateAccount;

